import React, { useRef, useEffect, useState } from "react"
import {  graphql } from "gatsby";
import mapboxgl from "!mapbox-gl"
import {
  Link,
  Trans,
  useTranslation,
  useI18next,
} from "gatsby-plugin-react-i18next";
import "mapbox-gl/dist/mapbox-gl.css"

const MAPBOX_TOKEN = "pk.eyJ1IjoibGR3NTEwIiwiYSI6ImNrc29xbndtbDFmM3AzMHBueTV0eTdxZXkifQ.KI8MCwMXTbD8CWs6619pzQ"

const mapContainerStyle = {
  width: "100%",
  height: "100vh",
}

const Map = () => {
  const mapContainerRef = useRef(null)

  const { t } = useTranslation();

  const [map, setMap] = useState(null)

//  const bounds = [
 //   [-7.123000755055273,52.24802801788758], // Southwest coordinates
//    [-7.098763377204335,52.271174997624655] // Northeast coordinates
 //   ];
 

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      accessToken: MAPBOX_TOKEN,
      style: "mapbox://styles/ldw510/cl5qj3akn003015nwenvjd7u4",
      center: [-7.10943943104080, 52.2593],
      zoom: 14.5
  //    maxBounds: bounds
    })
    

    var el = document.createElement('div');
    el.className = 'marker-ar';

    var el2 = document.createElement('div');
    el2.className = 'marker';

    var el3 = document.createElement('div');
    el3.className = 'marker-ar';

    var el4 = document.createElement('div');
    el4.className = 'marker';

    var el5 = document.createElement('div');
    el5.className = 'marker';

    var el6 = document.createElement('div');
    el6.className = 'marker-ar';

    var el7 = document.createElement('div');
    el7.className = 'marker';

    var el8 = document.createElement('div');
    el8.className = 'marker-ar';

    var el9 = document.createElement('div');
    el9.className = 'marker-ar';

    var el10 = document.createElement('div');
    el10.className = 'marker';

    var el11 = document.createElement('div');
    el11.className = 'marker';

    var el12 = document.createElement('div');
    el12.className = 'marker';

    var el13 = document.createElement('div');
    el13.className = 'marker-ar';

    var el14 = document.createElement('div');
    el14.className = 'marker';

    var el15 = document.createElement('div');
    el15.className = 'marker';

    const marker1 = new mapboxgl.Marker(el)
    .setLngLat([-7.105405219199042,52.260461836139655])
    .setPopup(
      new mapboxgl.Popup({ offset: 25 }) // add popups
        .setHTML(
          `<h3 id='map-popup-title'>Reginald's Tower</h3><a id='map-popup-button' href="/reginaldstower"><Trans>Visit Location<Trans/></a>`
        )
      )
    .addTo(map);


    // Create a default Marker, to Luke Wadding.
    const marker2 = new mapboxgl.Marker(el2)
    .setLngLat([-7.106955737494417,52.26040563395199])
    .setPopup(
      new mapboxgl.Popup({ offset: 25 }) // add popups
        .setHTML(
          `<h3 id='map-popup-title'>Greyfriars</h3><a id='map-popup-button' href="/greyfriars">Visit Location</a>`
        )
      )
    .addTo(map);

     // Create a default Marker, to Coffee House Lane.
     const marker3 = new mapboxgl.Marker(el3)
     .setLngLat([-7.107765164043428,52.260983093604295])
     .setPopup(
      new mapboxgl.Popup({ offset: 25 }) // add popups
        .setHTML(
          `<h3 id='map-popup-title'>Coffee House Lane</h3><a id='map-popup-button' href="/coffeehouselane">Visit Location</a>`
        )
      )
     .addTo(map);

      // Create a default Marker, to JC Mem.
      const marker4 = new mapboxgl.Marker(el4)
      .setLngLat([-7.107982750812312,52.260089794613094])
      .setPopup(
        new mapboxgl.Popup({ offset: 25 }) // add popups
          .setHTML(
            `<h3 id='map-popup-title'>Cathedral Square</h3><a id='map-popup-button' href="/cathedralsquare">Visit Location</a>`
          )
        )
      .addTo(map);

       // Create a default Marker, to aoife.
       const marker5 = new mapboxgl.Marker(el5)
       .setLngLat([-7.108056530388467,52.25964860199235])
       .setPopup(
        new mapboxgl.Popup({ offset: 25 }) // add popups
          .setHTML(
            `<h3 id='map-popup-title'>Aoife Strongbow Sculpture</h3><a id='map-popup-button' href="/aoifestrongbow">Visit Location</a>`
          )
        )
       .addTo(map);

        // Create a default Marker, to crystal.
        const marker6= new mapboxgl.Marker(el6)
        .setLngLat([-7.106838497256888,52.25932940815926])
        .setPopup(
          new mapboxgl.Popup({ offset: 25 }) // add popups
            .setHTML(
              `<h3 id='map-popup-title'>House of Waterford Crystal</h3><a id='map-popup-button' href="/waterfordcrystal">Visit Location</a>`
            )
          )
        .addTo(map);

         // Create a default Marker, to watch apple.
         const marker7= new mapboxgl.Marker(el7)
         .setLngLat([-7.111419121991474,52.25845697143012])
         .setPopup(
          new mapboxgl.Popup({ offset: 25 }) // add popups
            .setHTML(
              `<h3 id='map-popup-title'>Apple Market</h3><a id='map-popup-button' href="/applemarket">Visit Location</a>`
            )
          )
         .addTo(map);

          // Create a default Marker, to watch tower.
          const marker8= new mapboxgl.Marker(el8)
          .setLngLat([-7.11194781081258,52.256421195060966])
          .setPopup(
            new mapboxgl.Popup({ offset: 25 }) // add popups
              .setHTML(
                `<h3 id='map-popup-title'>Watch Tower</h3><a id='map-popup-button' href="/watchtower">Visit Location</a>`
              )
            )
          .addTo(map);

          // Create a default Marker, to fleming.
          const marker9= new mapboxgl.Marker(el9)
          .setLngLat([-7.110927476082821,52.26066138521574])
          .setPopup(
            new mapboxgl.Popup({ offset: 25 }) // add popups
              .setHTML(
                `<h3 id='map-popup-title'>Arundel Square</h3><a id='map-popup-button' href="/arundelsquare">Visit Location</a>`
              )
            )
          .addTo(map);

           // Create a default Marker, to blackfriars.
           const marker10= new mapboxgl.Marker(el10)
           .setLngLat([-7.110566236606246,52.26100546828215])
           .setPopup(
            new mapboxgl.Popup({ offset: 25 }) // add popups
              .setHTML(
                `<h3 id='map-popup-title'>Blackfriars</h3><a id='map-popup-button' href="/blackfriars">Visit Location</a>`
              )
            )
           .addTo(map);

           // Create a default Marker, to MHT.
           const marker11= new mapboxgl.Marker(el11)
           .setLngLat([-7.111512736720613,52.261554317958826])
           .setPopup(
            new mapboxgl.Popup({ offset: 25 }) // add popups
              .setHTML(
                `<h3 id='map-popup-title'>Cathedral of the Most Holy Trinity</h3><a id='map-popup-button' href="/mostholytrinity">Visit Location</a>`
              )
            )
           .addTo(map);

            // Create a default Marker, to clocktower.
            const marker12= new mapboxgl.Marker(el12)
            .setLngLat([-7.111469105103453,52.26220152579157])
            .setPopup(
              new mapboxgl.Popup({ offset: 25 }) // add popups
                .setHTML(
                  `<h3 id='map-popup-title'>Clocktower</h3><a id='map-popup-button' href="/clocktower">Visit Location</a>`
                )
              )
            .addTo(map);

              // Create a default Marker, to granville.
              const marker13= new mapboxgl.Marker(el13)
              .setLngLat([-7.112212959638583,52.262173351938486])
              .setPopup(
                new mapboxgl.Popup({ offset: 25 }) // add popups
                  .setHTML(
                    `<h3 id='map-popup-title'>Granville Hotel</h3><a id='map-popup-button' href="/granville">Visit Location</a>`
                  )
                )
              .addTo(map);
            
             // Create a default Marker, to commerce.
             const marker14= new mapboxgl.Marker(el14)
             .setLngLat([-7.113704320430912,52.2617872477573])
             .setPopup(
              new mapboxgl.Popup({ offset: 25 }) // add popups
                .setHTML(
                  `<h3 id='map-popup-title'>The Port of Waterford Building</h3><a id='map-popup-button' href="/chambercommerce">Visit Location</a>`
                )
              )
             .addTo(map);

              // Create a default Marker, to beach tower.
              const marker15= new mapboxgl.Marker(el15)
              .setLngLat([-7.11372382726114,52.26129458463947])
              .setPopup(
                new mapboxgl.Popup({ offset: 25 }) // add popups
                  .setHTML(
                    `<h3 id='map-popup-title'>Beach Tower</h3><a id='map-popup-button' href="/beachtower">Visit Location</a>`
                  )
                )
              .addTo(map);

      // Initialize the geolocate control.
  const geolocate = new mapboxgl.GeolocateControl({
  positionOptions: {
  enableHighAccuracy: true
  },
  trackUserLocation: true,
  showAccuracyCircle: false
  });
  // Add the control to the map.
  map.addControl(geolocate);
  map.on('load', () => {
  geolocate.trigger();
  });


              map.on('load', () => {
                map.addSource('route', {
                'type': 'geojson',
                'data': {
                'type': 'Feature',
                'properties': {},
                'geometry': {
                'type': 'LineString',
                'coordinates': [
                [-7.105273953329657,52.260563910081544],
                [-7.105489955886384,52.26070493279698],
                [-7.106281965262809,52.26026864231795],
                [-7.107019973998291,52.260237793333886],
                [-7.1070059204517975,52.26040458241684],
                [-7.106908058628989,52.26098571060942],
                [-7.107783904510995,52.26115992473041],
                [-7.1077817610773195,52.26115651461157],
                [-7.108052530084933,52.26051223718102],
                [-7.107991924169568,52.26008164572198],
                [-7.108010835268658,52.25972458475644],
                [-7.1082562241483345,52.25965352310098],
                [-7.108002919499853,52.25904772476301],
                [-7.106981785132518,52.25942080227961],
                [-7.108002919499853,52.25904772476301],
                [-7.111353950197412,52.25735789043432],
                [-7.111503869177795,52.257277253706775],
                [-7.112283096880617,52.25650968442105],
                [-7.111503869177795,52.257277253706775],
                [-7.1116990020198045,52.25762015531251],
                [-7.111739359787265,52.25802156302876],
                [-7.111522491449563,52.25838297655599],
                [-7.111826313988388,52.25841122519415],
                [-7.1118431891925695,52.26059129480484],
                [-7.110965214587765,52.260578397613756],
                [-7.110986838436219,52.26084721031006],
                [-7.110622335129449,52.26080402951692],
                [-7.110545215414504,52.26100218222791],
                [-7.110622335129449,52.26080402951692],
                [-7.110986838436219,52.26084721031006],
                [-7.111689913533439,52.2609541766239],
                [-7.111581466295746,52.2616054442143],
                [-7.111460599298681,52.26206496625315],
                [-7.1114046283946095,52.262198229859365],
                [-7.111460599298681,52.26206496625315],
                [-7.112148715008402,52.262188647236286],
                [-7.113242429526196,52.26241119986895],
                [-7.11363443137418,52.26180970778694],
                [-7.113294022444336,52.2616930516852],
                [-7.113397689652231,52.2615520788525],
                [-7.113532018108145,52.261428181923094],
                [-7.113531557783119,52.26137721813208],
                [-7.113732923975135,52.26121676338306]
                ]
                }
                }
                });
                map.addLayer({
                'id': 'route',
                'type': 'line',
                'source': 'route',
                'layout': {
                'line-join': 'round',
                'line-cap': 'round'
                },
                'paint': {
                'line-color': 'orange',
                'line-width': 4
                }
                });
                });

    map.addControl(new mapboxgl.NavigationControl(), "top-right")


    setMap(map)

    return () => map.remove()
  }, [])

  return <div className="pt-16" ref={mapContainerRef} style={mapContainerStyle} />
}

export default Map

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
